import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';

const meta = {
  title:
    'ShortsPub\u0020\u002D\u0020Where\u0020Stories\u0020come\u0020Alive\u0021',
  meta: [
    {
      property: 'og\u003Aimage',
      content:
        'https\u003A//s3.ap\u002Dsouth\u002D1.amazonaws.com/vv\u002Dzook\u002Dpublic/hq\u002Dassets\u002Dv1/v3/files/202404/1712643493_8lffe8clll579rvu.svg',
    },
    {
      property: 'og\u003Adescription',
      content:
        'Get\u0020ready\u0020to\u0020immerse\u0020yourself\u0020in\u0020a\u0020world\u0020of\u0020imagination\u0020and\u0020adventure.\u0020Get\u0020access\u0020to\u0020an\u0020endless\u0020array\u0020of\u0020captivating\u0020short\u0020stories.',
    },
    {
      property: 'og\u003Atitle',
      content:
        'ShortsPub\u0020\u002D\u0020Where\u0020Stories\u0020come\u0020Alive\u0021',
    },
    {
      name: 'description',
      content:
        'Get\u0020ready\u0020to\u0020immerse\u0020yourself\u0020in\u0020a\u0020world\u0020of\u0020imagination\u0020and\u0020adventure.\u0020Get\u0020access\u0020to\u0020an\u0020endless\u0020array\u0020of\u0020captivating\u0020short\u0020stories.',
    },
  ],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
    </React.Fragment>
  );
}

