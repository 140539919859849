import React from 'react';

export default function IndexSectionHeaders1() {
    return (
        <React.Fragment>
            <>
                <section className="pb-12 bg-white"><div className="container pt-12">
    <p className="text-center mb-24">
      <img alt="shortspub" src="https://s3.ap-south-1.amazonaws.com/vv-zook-public/hq-assets-v1/v3/files/202404/1712643493_8lffe8clll579rvu.svg" className="sp-home-image" /></p>
    <div className="mw-lg-3xl mx-auto mb-24 text-center">
      <h3 className="mb-8" contentEditable="false">Submit Story or Poem</h3>
      <p className="lead mw-xl mx-auto mb-10 p-4" contentEditable="true">
        Short stories that pack a punch (500-3000 words)
        <br />
        Super easy writer app — write and translate
        <br />
        Audio conversion — immerse yourself in a world of sound!
      </p>
      <p className="lead mw-xl mx-auto mb-8 p-4 sp-teaser-text fw-bold">
        Write
        <span className="text-warning">Read</span>
        Listen
      </p>
      <p className="mw-xl mx-auto mb-8 p-4 text-justify">Get ready to immerse yourself in a world of imagination and adventure. With our platform, you'll have access to an endless array of captivating short stories, each one more exciting than the last. You can explore different genres, from romance to sci-fi, and discover new authors who will transport you to new worlds and introduce you to fascinating characters. Whether you're looking to escape reality for a little while or simply want to indulge in some great storytelling, our platform has got you covered. So buckle up and get ready to embark on a thrilling journey that will keep you turning the pages until the very end!</p>
      <p className="mw-xl mx-auto mt-8 mb-8 p-4">Submit Story or Poem by email to write@shortspub.com</p>
    </div>
    <div className="mx-auto justify-content-between align-items-center">
      <div className="sp-social-bar">
        <a href="https://instagram.com/shortspub" target="_blank" rel="noreferrer noopener" className="jsx-69c961a89b02cf2c home-link1">
          <svg viewBox="0 0 877.7142857142857 1024" className="sp-social-icons"><path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z" className="jsx-69c961a89b02cf2c" /></svg></a>
        <a href="https://www.facebook.com/myshortspub" target="_blank" rel="noreferrer noopener" className="jsx-69c961a89b02cf2c home-link2">
          <svg viewBox="0 0 602.2582857142856 1024" className="sp-social-icons"><path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z" className="jsx-69c961a89b02cf2c" /></svg></a>
        <a href="https://www.youtube.com/@myshortspub" target="_blank" rel="noreferrer noopener" className="jsx-69c961a89b02cf2c home-link3">
          <svg viewBox="0 0 1024 1024" className="sp-social-icons"><path d="M1013.8 307.2c0 0-10-70.6-40.8-101.6-39-40.8-82.6-41-102.6-43.4-143.2-10.4-358.2-10.4-358.2-10.4h-0.4c0 0-215 0-358.2 10.4-20 2.4-63.6 2.6-102.6 43.4-30.8 31-40.6 101.6-40.6 101.6s-10.2 82.8-10.2 165.8v77.6c0 82.8 10.2 165.8 10.2 165.8s10 70.6 40.6 101.6c39 40.8 90.2 39.4 113 43.8 82 7.8 348.2 10.2 348.2 10.2s215.2-0.4 358.4-10.6c20-2.4 63.6-2.6 102.6-43.4 30.8-31 40.8-101.6 40.8-101.6s10.2-82.8 10.2-165.8v-77.6c-0.2-82.8-10.4-165.8-10.4-165.8zM406.2 644.8v-287.8l276.6 144.4-276.6 143.4z" className="jsx-69c961a89b02cf2c" /></svg></a>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

